var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "videoTmp" },
    [
      _c(
        "el-form",
        {
          attrs: { model: _vm.data },
          nativeOn: {
            submit: function ($event) {
              $event.preventDefault()
            },
          },
        },
        [
          _c("el-form-item", { attrs: { label: "视频:" } }, [
            _c("div", { staticClass: "x-bc" }, [
              _c("div", { staticClass: "x-f" }),
              _c(
                "div",
                { staticClass: "x-f btnListBox" },
                [
                  _c("OssUpload", {
                    attrs: {
                      type: ["mp4"],
                      showList: false,
                      tip: false,
                      buttonName: "上传视频",
                      size: "10mb",
                    },
                    model: {
                      value: _vm.data.style.videoUrl,
                      callback: function ($$v) {
                        _vm.$set(_vm.data.style, "videoUrl", $$v)
                      },
                      expression: "data.style.videoUrl",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c(
              "div",
              { staticClass: "y-start marT10" },
              [
                _c("el-input", {
                  attrs: { placeholder: "请输入地址链接" },
                  model: {
                    value: _vm.data.style.videoUrl,
                    callback: function ($$v) {
                      _vm.$set(_vm.data.style, "videoUrl", $$v)
                    },
                    expression: "data.style.videoUrl",
                  },
                }),
                _c(
                  "span",
                  {
                    staticClass: "fS14MR10",
                    staticStyle: { color: "#969799" },
                  },
                  [
                    _vm._v(
                      "小程序 v2.15 及以上版本支持仅支持.mp4格式的视频源地址"
                    ),
                  ]
                ),
                _c(
                  "video",
                  {
                    staticStyle: { background: "#000000" },
                    attrs: {
                      src: _vm.data.style.videoUrl,
                      height: "200px",
                      width: "95%",
                      poster: _vm.data.style.coverImg,
                    },
                  },
                  [_vm._v(" your browser does not support the video tag ")]
                ),
              ],
              1
            ),
          ]),
          _c("el-form-item", { attrs: { label: "封面:" } }, [
            _c("div", { staticClass: "x-bc" }, [
              _c("div", { staticClass: "x-f" }),
              _c(
                "div",
                { staticClass: "x-f btnListBox fS14MR10" },
                _vm._l(_vm.coverImgList, function (item, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass: "item x-c",
                      class: { borderColor: _vm.sourceItem == index },
                      on: {
                        click: function ($event) {
                          return _vm.setCover(item.style, index)
                        },
                      },
                    },
                    [_c("span", [_vm._v(_vm._s(item.name))])]
                  )
                }),
                0
              ),
            ]),
          ]),
          _vm.coverImgItem == 1
            ? _c("div", [
                _c(
                  "div",
                  {
                    staticClass: "addImgBox x-c",
                    on: { click: _vm.addCoverImg },
                  },
                  [
                    !_vm.data.style.coverImg
                      ? _c("i", {
                          staticClass: "el-icon-plus",
                          staticStyle: {
                            "font-size": "26px",
                            color: "#bababa",
                          },
                        })
                      : _c("el-image", {
                          attrs: {
                            src: _vm.data.style.coverImg,
                            width: "100%",
                          },
                        }),
                  ],
                  1
                ),
                _c(
                  "span",
                  { staticClass: "fontS12", staticStyle: { color: "#969799" } },
                  [_vm._v("建议图宽高比例与视频宽高比一致")]
                ),
              ])
            : _vm._e(),
          _c("el-divider"),
          _c("div", [
            _c("div", { staticClass: "x-bc" }, [
              _c("span", { staticClass: "title" }, [_vm._v("播放设置")]),
              _c("i", {
                staticClass: "fontS16 pointer",
                class: _vm.jiantou1,
                on: { click: _vm.openDownFn },
              }),
            ]),
            _vm.openDown
              ? _c(
                  "div",
                  { staticClass: "marT10" },
                  [
                    _c("el-form-item", [
                      _c("div", { staticClass: "x-bc" }, [
                        _c("div", { staticClass: "x-f" }, [
                          _c("span", { staticClass: "marR20 label" }, [
                            _vm._v("进度条"),
                          ]),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.data.style.progressBar ? "显示" : "不显示"
                              )
                            ),
                          ]),
                        ]),
                        _c(
                          "div",
                          { staticClass: "x-f btnListBox" },
                          [
                            _c("el-switch", {
                              attrs: { size: "mini" },
                              model: {
                                value: _vm.data.style.progressBar,
                                callback: function ($$v) {
                                  _vm.$set(_vm.data.style, "progressBar", $$v)
                                },
                                expression: "data.style.progressBar",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]),
                    _c("el-form-item", [
                      _c("div", { staticClass: "x-bc" }, [
                        _c("div", { staticClass: "x-f" }, [
                          _c("span", { staticClass: "marR20 label" }, [
                            _vm._v("自动播放"),
                          ]),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.data.style.voluntarilyPlay
                                  ? "已开启"
                                  : "已关闭"
                              )
                            ),
                          ]),
                        ]),
                        _c(
                          "div",
                          { staticClass: "x-f btnListBox" },
                          [
                            _c("el-switch", {
                              attrs: { size: "mini" },
                              model: {
                                value: _vm.data.style.voluntarilyPlay,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.data.style,
                                    "voluntarilyPlay",
                                    $$v
                                  )
                                },
                                expression: "data.style.voluntarilyPlay",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]),
                  ],
                  1
                )
              : _vm._e(),
          ]),
          _c("el-divider"),
          _c("div", [
            _c("div", { staticClass: "x-bc" }, [
              _c("span", { staticClass: "title" }, [_vm._v("样式设置")]),
              _c("i", {
                staticClass: "fontS16 pointer",
                class: _vm.jiantou2,
                on: { click: _vm.open },
              }),
            ]),
            _vm.openChamfer
              ? _c(
                  "div",
                  { staticClass: "marT10" },
                  [
                    _c("el-form-item", [
                      _c("div", { staticClass: "x-bc" }, [
                        _c("div", { staticClass: "x-f" }, [
                          _c("span", { staticClass: "marR20 label" }, [
                            _vm._v("视频倒角"),
                          ]),
                        ]),
                        _c(
                          "div",
                          { staticClass: "x-f btnListBox" },
                          _vm._l(_vm.chamferList, function (item, index) {
                            return _c(
                              "div",
                              {
                                key: index,
                                staticClass: "item x-c",
                                class: {
                                  borderColor: _vm.chamferItem == index,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.setChamfer(item.style, index)
                                  },
                                },
                              },
                              [_c("span", [_vm._v(_vm._s(item.name))])]
                            )
                          }),
                          0
                        ),
                      ]),
                    ]),
                    _c(
                      "el-form-item",
                      { attrs: { label: "上边距", "label-width": "" } },
                      [
                        _c(
                          "div",
                          { staticClass: "x-f" },
                          [
                            _c("el-slider", {
                              staticStyle: { width: "300px" },
                              attrs: { max: 30, "show-input": "" },
                              on: {
                                change: function ($event) {
                                  return _vm.selectpagebackGauge(
                                    _vm.data.style.pagePaddingTop,
                                    "top"
                                  )
                                },
                              },
                              model: {
                                value: _vm.data.style.pagePaddingTop,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.data.style,
                                    "pagePaddingTop",
                                    $$v
                                  )
                                },
                                expression: "data.style.pagePaddingTop",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "下边距", "label-width": "" } },
                      [
                        _c(
                          "div",
                          { staticClass: "x-f" },
                          [
                            _c("el-slider", {
                              staticStyle: { width: "300px" },
                              attrs: { max: 30, "show-input": "" },
                              on: {
                                change: function ($event) {
                                  return _vm.selectpagebackGauge(
                                    _vm.data.style.pagePaddingBottom,
                                    "bottom"
                                  )
                                },
                              },
                              model: {
                                value: _vm.data.style.pagePaddingBottom,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.data.style,
                                    "pagePaddingBottom",
                                    $$v
                                  )
                                },
                                expression: "data.style.pagePaddingBottom",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "左边距", "label-width": "" } },
                      [
                        _c(
                          "div",
                          { staticClass: "x-f" },
                          [
                            _c("el-slider", {
                              staticStyle: { width: "300px" },
                              attrs: { max: 30, "show-input": "" },
                              on: {
                                change: function ($event) {
                                  return _vm.selectpagebackGauge(
                                    _vm.data.style.pagePaddingLeft,
                                    "left"
                                  )
                                },
                              },
                              model: {
                                value: _vm.data.style.pagePaddingLeft,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.data.style,
                                    "pagePaddingLeft",
                                    $$v
                                  )
                                },
                                expression: "data.style.pagePaddingLeft",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "右边距", "label-width": "" } },
                      [
                        _c(
                          "div",
                          { staticClass: "x-f" },
                          [
                            _c("el-slider", {
                              staticStyle: { width: "300px" },
                              attrs: { max: 30, "show-input": "" },
                              on: {
                                change: function ($event) {
                                  return _vm.selectpagebackGauge(
                                    _vm.data.style.pagePaddingRight,
                                    "right"
                                  )
                                },
                              },
                              model: {
                                value: _vm.data.style.pagePaddingRight,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.data.style,
                                    "pagePaddingRight",
                                    $$v
                                  )
                                },
                                expression: "data.style.pagePaddingRight",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ]),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
          attrs: {
            title: "素材库",
            visible: _vm.showDialog,
            width: "80%",
            "destroy-on-close": true,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showDialog = $event
            },
          },
        },
        [
          _c("Material", {
            attrs: { isDialog: true, isRadio: true },
            on: { accessUrlListCom: _vm.getAccessUrlList },
          }),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.imgUpdateSubmit },
                },
                [_vm._v("确 定")]
              ),
              _c("el-button", { on: { click: _vm.imgUpdateCancel } }, [
                _vm._v("取 消"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }